import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import apiService from "../core/apiService";
import premium from "../assets/images/crown.png";
import icehocky from "../assets/images/ice_hockey.svg";
import logo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

const LeftNav = ({ toggle, handleClick }) => {
  const restaurant = useSelector((state) => state.restaurant);
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const [isMenuSubMenu2, setMenuSubMenu2] = useState(false);
  const [isMenuSubMenu3, setMenuSubMenu3] = useState(false);
  const [isMenuSubMenu4, setMenuSubMenu4] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [access, setAccess] = useState("");
  const subscribe = useSelector((state) => state.subscribe);

  const [cookies] = useCookies([
    "userId",
    "userToken",
    "restaurantId",
    "userAccess",
  ]);
  const userAccess = cookies.userAccess;
  const toggleSubmenu = () => {
    setMenuSubMenu(!isMenuSubMenu);
  };
  useEffect(() => {
    if (userAccess) {
      setAccess(atob(userAccess));
    }
  }, [userAccess]);
  const toggleSubmenu2 = () => {
    setMenuSubMenu2(!isMenuSubMenu2);
  };

  const toggleSubmenu3 = () => {
    setMenuSubMenu3(!isMenuSubMenu3);
  };

  const toggleSubmenu4 = () => {
    setMenuSubMenu4(!isMenuSubMenu4);
  };

  console.log(subscribe, "restaurantrestaurant");
  const showPrium = (ele) =>
    dashboardDetails.PremiumFeature?.find(
      (item) => item.feature.feature_name === ele
    ) ? (
      <img className="img-premium" src={premium} alt="" />
    ) : (
      ""
    );
  let currentDate = new Date();
  let today = currentDate.toISOString().split("T")[0];
  const premiumStyle = (ele) => {
	if (access !== "Admin") {
	  if (
		restaurant.verified === 1 &&
		subscribe.restaurant_subscription_end_date > today
	  ) {
		const featureExists = dashboardDetails?.subscriptionFeatures?.find(
		  (item) => item.feature.feature_name === ele
		);
  
		return featureExists
		  ? {} // Feature exists, no style applied
		  : { pointerEvents: "none", opacity: "0.4" }; // Feature doesn't exist, disable it
	  }
  
	  // If restaurant is not verified or subscription is expired
	  return { pointerEvents: "none", opacity: "0.4" };
	}
  
	// If access is "Admin", no style applied
	return {};
  };
  

  useEffect(() => {
    apiService.get(
      `/restaurants/dashboard/${restaurant.id}&enabled=1`,
      {},
      ({ err, data }) => {
        if (data.success) {
          console.log(data.data);
          setDashboardDetails(data.data);
        } else {
          console.log(err);
        }
      }
    );
  }, []);

  let boxClassSubMenu = ["sub-menu"];
  let boxClassSubMenu2 = ["sub-menu"];
  let boxClassSubMenu3 = ["sub-menu"];
  let boxClassSubMenu4 = ["sub-menu"];

  if (isMenuSubMenu) {
    boxClassSubMenu.push("show");
  }

  if (isMenuSubMenu2) {
    boxClassSubMenu2.push("show");
  }

  if (isMenuSubMenu3) {
    boxClassSubMenu3.push("show");
  }

  if (isMenuSubMenu4) {
    boxClassSubMenu4.push("show");
  }

  return (
    <div className={`side-nav ${toggle ? "collapsed" : ""}`}>
      <div className="dashboard-logo">
        <Link to="/dashboard">
          <img src={logo} alt="Whatson" className="large-logo" />
          <img alt="" src={logo} className="small-logo" />
        </Link>
      </div>

      <div className="navigation-bar">
        <h4>
          <span>Main</span> Menu
        </h4>
        <div className="toggle-icon" onClick={handleClick}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <ul>
          <li>
            <NavLink to="/dashboard" exact="true" activeClassName="active">
              <span className="menu-icon dashboard-icon"></span>
              <span className="menu-title">Dashboard</span>
            </NavLink>
          </li>
          <li className="has-sub" onClick={toggleSubmenu}>
            <span style={premiumStyle("Daily specials")}>
              <a href="javascript:void(0)">
                <span className="menu-daily-special-icon promotion-icon"></span>
                <span className="menu-title">
                  Daily Specials {showPrium("Daily specials")}
                </span>
                <span className="open-menu">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </a>
              <ul className={boxClassSubMenu.join(" ")}>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/daily-special/food"
                  >
                    Food
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/daily-special/drink"
                  >
                    Drink
                  </NavLink>
                </li>
              </ul>
            </span>
          </li>
          <li className="has-sub" onClick={toggleSubmenu2}>
            <span style={premiumStyle("Game day specials")}>
              <a href="javascript:void(0)">
                <img src={icehocky} alt="" />
                <span className="menu-title">
                  Game Day Specials {showPrium("Game day specials")}
                </span>
                <span className="open-menu">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </a>
              <ul className={boxClassSubMenu2.join(" ")}>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/game-day-special/food"
                  >
                    Food
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/game-day-special/drink"
                  >
                    Drink
                  </NavLink>
                </li>
              </ul>
            </span>
          </li>
          <li onClick={toggleSubmenu3}>
            <span className="has-sub" style={premiumStyle("Happy hours")}>
              <a href="javascript:void(0)">
                <span className="menu-happy-hours-special-icon promotion-icon"></span>
                <span className="menu-title">
                  Happy Hours {showPrium("Happy hours")}
                </span>
                <span className="open-menu">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </a>
              <ul className={boxClassSubMenu3.join(" ")}>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/happy-hour/food"
                  >
                    Food
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/happy-hour/drink"
                  >
                    Drink
                  </NavLink>
                </li>
              </ul>
            </span>
          </li>
          <li onClick={toggleSubmenu4}>
            <span className="has-sub" style={premiumStyle("Whatson tap")}>
              <a href="javascript:void(0)">
                <span className="menu-on-tap-special-icon promotion-icon"></span>
                <span className="menu-title">
                  On Tap {showPrium("Whatson tap")}
                </span>
                <span className="open-menu">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </a>
              <ul className={boxClassSubMenu4.join(" ")}>
                <li>
                  <NavLink
                    exact="true"
                    activeClassName="active"
                    to="/on-tap/drink"
                  >
                    Drink
                  </NavLink>
                </li>
              </ul>
            </span>
          </li>
          <li
            style={
              access != "Admin"
                ? (restaurant.verified == 1 && subscribe.restaurant_subscription_end_date > today)
                  ? {}
                  : { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <NavLink exact="true" activeClassName="active" to="/request-admin">
              <span className="menu-icon request-icon"></span>
              <span className="menu-title">Request to Admin</span>
            </NavLink>
          </li>
          <li style={premiumStyle("Whatson mode")}>
            <NavLink exact="true" activeClassName="active" to="/communication">
              <span className="menu-icon communication-icon"></span>
              <span className="menu-title">
                Communication {showPrium("Whatson mode")}
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftNav };
